<template>
  <div class="crash-game-page">
    <div class="crash-game-wrapper" :class="{ showTrendBet: showTrendBet }">
      <div class="crash-game" :class="{ go: gameStatus === 'START' }">
        <CrashGame
          ref="gameChart"
          :PoolSize="gamePoolSize"
          :HistorySystem="gameHistoryResult"
          :MessageSocket="gameMessageSocket"
          :GameStatus="gameStatus"
          :Countdown="gameCountDown"
          :CounterChart="gameTiming"
          :ChartData="gameChartData"
        ></CrashGame>
        <CrashActions
          ref="gameAction"
          :GameStatus="gameStatus"
          :CounterChart="gameTiming"
          :StopPoint="gameStopPoint"
          :AllUserBet="gameAnyBetting"
          :PoolSize="gamePoolSize"
          :MaxPool="maxPool"
          @adminControls="adminControls"
          @depositPool="depositPool"
          @withdrawPool="withdrawPool"
          @setMaxPool="setMaxPool"
        ></CrashActions>
      </div>

      <div v-if="false" class="table-header">
        <button
          class="botan gradient-text"
          :class="{ active: currentTable === 'trend' }"
          @click="currentTable = 'trend'"
        >
          <img src="@/assets/images/icons/dices.svg" />
          Trendball
        </button>
        /
        <button
          class="botan gradient-text"
          :class="{ active: currentTable === 'classic' }"
          @click="currentTable = 'classic'"
        >
          <img src="@/assets/images/icons/dices.svg" />
          Classic
        </button>
        <div v-if="false">
          Trendball
          <div class="privacy-switch">
            <input type="checkbox" id="gaming" v-model="currentTable" />
            <label for="gaming">Toggle</label>
          </div>
        </div>
      </div>
      <div class="crash-table">
        <div class="crash-table-content">
          <!-- v-else -->
          <BetListedClassic :AllUserBet="gameAnyBetting"></BetListedClassic>
          <!-- v-if="currentTable" -->
          <BetListedTrendBall
            :Show="showTrendBet"
            :AllUserBet="gameAnyBetting"
            @togTrend="togTrend"
          ></BetListedTrendBall>
        </div>
      </div>
    </div>
    <div class="sub-content">
      <HistoryCrash v-if="UserInfo?.level === 1" :AllUserOnline="userOnline" />
    </div>
  </div>
</template>

<script>
import { io } from "socket.io-client";

import CrashGame from "../components/crashgame/CrashGame.vue";
import BetListedClassic from "../components/crashgame/BetListedClassic.vue";
import BetListedTrendBall from "../components/crashgame/BetListedTrendBall.vue";
import CrashActions from "../components/crashgame/CrashActions.vue";
import HistoryCrash from "@/components/crashgame/HistoryCrash.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CrashPage",
  components: {
    CrashGame,
    BetListedClassic,
    BetListedTrendBall,
    CrashActions,
    HistoryCrash,
  },
  data() {
    return {
      currentSub: "crash",
      currentTable: "classic",

      // define socket data
      socket: null,
      socketStatus: null,

      // game information for current user
      gameStatus: "CONNECTING",
      userCurrentBet: null,
      tempBalance: 0,
      gameChartData: {},
      gameHistoryResult: [],
      gameMessageSocket: {},
      gameAnyBetting: {
        bet_classic: [],
        bet_red: [],
        bet_green: [],
        bet_moon: [],
      },
      gameTiming: 0,
      gameCountDown: 0,
      gamePoolSize: 0,
      gameStopPoint: 0,
      maxPool: 0,

      allOnlineUser: [],

      // User bet history divided into 3 arrays
      userBetHistory: undefined,
      userOnline: null,
      showTrendBet: false,
    };
  },
  computed: {
    ...mapGetters({
      TypeBalanceSelected: "socket/TypeBalanceSelected",
      CurrentTab: "socket/CurrentTab",
      UserInfo: "userinfo/UserInfo",
    }),
    UserBetHistory() {
      if (this.userBetHistory) {
        switch (this.CurrentTab) {
          case "userBet":
            return this.userBetHistory.user_history;
          case "userBetMain":
            return this.userBetHistory.user_history_main;
          case "userBetStaking":
            return this.userBetHistory.user_history_staking;
        }
      }
      return {};
    },
  },
  methods: {
    ...mapMutations(["onload", "outload"]),
    async connectWSSToken(tokens) {
      let self = this;
      // VUE_APP_SOCKET_MAIN
      // VUE_APP_SOCKET_BETA
      // eslint-disable-next-line no-undef
      this.socket = await io(process.env.VUE_APP_SOCKET_BETA, {
        reconnectionDelayMax: 10000,
        reconnection: false,
        query: {
          token: tokens,
          lang: this.$i18n.locale,
        },
      });

      this.socket.on("COUNT_DOWN", (data) => {
        self.gameCountDown = data;
      });

      this.socket.on("CHANGE_STATUS", (data) => {
        this.gameStatus = data;
        if (data === "END") {
          self.userCurrentBet = null;
          // const gameChart = self.$refs.gameChart;
          // if (gameChart) {
          //   gameChart.stopChart();
          // }
          this.resetGame();
        }
      });

      this.socket.on("CHART_DATA", (data) => {
        // Socket returns an array, get last item in array
        this.gameChartData = data[data.length - 1];
      });

      this.socket.on("GET_BALANCE", (data) => {
        this.tempBalance = data;
        this.$store.commit("socket/SET_BALANCE", data);
      });

      this.socket.on("STOP_POINT", (data) => {
        this.gameStopPoint = +data.stop_point;
      });

      this.socket.on("HISTORY", (data) => {
        this.gameHistoryResult = data.data;
      });

      this.socket.on("ADMIN_USER_BET", (data) => {
        console.log(data);
        this.gameAnyBetting = data;
      });

      this.socket.on("HISTORY_USER", (data) => {
        this.userBetHistory = data;
      });

      this.socket.on("COUNTER", (data) => {
        this.gameTiming = +data;
      });

      this.socket.on("POOL_SIZE", (data) => {
        this.gamePoolSize = data;
      });

      this.socket.on("MESSAGE", (data) => {
        console.log("action: ", data.action);
        if (data.status) {
          this.$vToastify.success(data.message, "Success!");
        } else {
          this.$vToastify.error("", data.message);
        }

        if (data.action === "LogOut") {
          this.$store.commit("auth/LOGOUT_SUCCESS");
          this.$router.push("/login");
        }

        // Handle admin control
        if (data.status && data.action === "set stop point") {
          this.$refs.gameAction.controlResult = data.result;
        }

        // Handle failed bet
        if (!data.status) {
          switch (data.action) {
            case "BET":
              this.$refs.gameAction.isBet = false;
              break;
            case "BET RED":
              this.$refs.gameAction.isBetRed = false;
              break;
            case "BET_GREEN":
              this.$refs.gameAction.isBetGreen = false;
              break;
            case "BET_MOON":
              this.$refs.gameAction.isBetMoon = false;
          }
        }
      });

      this.socket.on("GET_TOTAL_USER_ONLINE", (data) => {
        this.userOnline = data;
      });
      this.socket.on('MAX_POOL', (data) => {
        this.maxPool = data;
      })
    },
    async resetGame() {
      const gameAction = await this.$refs.gameAction;
      if (!gameAction) {
        this.resetGame();
      }
      this.$refs.gameAction.controlResult = 0;
      this.gameStopPoint = 0;
    },
    adminControls(input) {
      if (typeof input === "number") {
        this.socket.emit("ADMIN_SET_POINT", { value: input, type: "CLASSIC" });
      } else {
        this.socket.emit("ADMIN_SET_POINT", { type: input });
      }
    },
    depositPool(input) {
      this.socket.emit("DEPOSIT_POOL", input);
    },
    withdrawPool(input) {
      this.socket.emit("WITHDRAW_POOL", input);
    },
    togTrend(bool) {
      this.showTrendBet = bool;
      setTimeout(this.$refs.gameChart.handleResize, 100);
    },
    setMaxPool(input) {
      this.socket.emit('SET_MAX_POOL', input);
    }
  },
  async mounted() {
    this.onload();
    const self = this;
    const check = setInterval(async () => {
      const gameChart = await self.$refs.gameChart;
      if (gameChart) {
        clearInterval(check);
        return;
      }
    });

    await new Promise((resolve) => setTimeout(resolve, 500));
    this.outload();
    const tokenSocket = window.$cookies.get("socket_session");
    this.connectWSSToken(tokenSocket.key);
  },
  beforeDestroy() {
    this.socket.disconnect();
    this.$store.commit("socket/SET_BALANCE", {});
  },
};
</script>

<style lang="scss" scoped>
.crash-game-page {
  .crash-game-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0.5rem;
    .crash-game {
      // background-image: url("~@/assets/images/starry.svg"),
      //   linear-gradient(180deg, #081e43 0%, rgba(1, 6, 15, 0.63) 100%);

      // Animation
      // background-size: 200% 200%, 100%;
      // animation: bgPan 10s linear infinite;
      animation-play-state: paused;
      &.go {
        // animation-play-state: running;
      }
      @keyframes bgPan {
        from {
          background-position: 0 0;
        }
        to {
          background-position: 200% -200%;
        }
      }
      ///////////////////////////
    }
    .table-header {
      font-weight: 700;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      padding: 5px 10px;
      .botan {
        opacity: 0.6;

        &.active {
          opacity: 1;
        }
      }
      .privacy-switch {
        height: 25px;
        display: inline-block;
        input[type="checkbox"] {
          height: 0;
          width: 0;
          visibility: hidden;
        }

        label {
          cursor: pointer;
          text-indent: -9999px;
          width: 50px;
          height: 25px;
          background: #1165948a;
          display: block;
          border-radius: 100px;
          position: relative;
          margin-top: -25px;
        }

        label:after {
          content: "";
          position: absolute;
          top: 5px;
          left: 5px;
          width: 15px;
          height: 15px;
          background: #656b7d;
          border-radius: 90px;
          transition: 0.3s;
        }

        input:checked + label {
          background: rgba(255, 203, 18, 0.1);
        }

        input:checked + label:after {
          left: calc(100% - 5px);
          transform: translateX(-100%);
          background: #ffcb12;
        }

        label:active:after {
          width: 50px;
        }
      }
    }
    .crash-table {
      width: 100%;
      .crash-table-content {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        @media (min-width: 992px) {
        }
      }
    }
    @media (min-width: 768px) {
      .table-header {
        padding: 5px 0px;
      }
    }
    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr;
      .crash-game {
        grid-row: 2;
      }
      .crash-table {
        grid-row: 2;
        .crash-table-content {
          grid-template-columns: 1fr auto;
        }
      }
      .table-header {
        display: none;
      }
      &.showTrendBet {
        grid-template-columns: 3.5fr 8.5fr;
        .crash-table {
          .crash-table-content {
            grid-template-columns: 1fr 1fr;
          }
        }
      }
    }
  }
  .tabs-wrapper {
    display: inline-block;
    margin: 30px 10px;
    @media (min-width: 768px) {
      margin: 30px 0px;
    }
    .botan {
      padding: 10px 30px;
    }
  }
  @media (min-width: 768px) {
    padding: 1rem;
  }
  @media (min-width: 1400px) {
    padding: 1rem 2rem;
  }
}
</style>
