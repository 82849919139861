<template>
  <div class="crash-game-page">
    <div class="crash-game-wrapper" :class="{ showTrendBet: showTrendBet }">
      <div class="crash-game" :class="{ go: gameStatus === 'START' }">
        <CrashGame ref="gameChart" :PoolSize="gamePoolSize" :HistorySystem="gameHistoryResult"
          :MessageSocket="gameMessageSocket" :GameStatus="gameStatus" :Countdown="gameCountDown"
          :CounterChart="gameTiming" :ChartData="gameChartData"></CrashGame>
        <CrashActions ref="gameAction" :GameStatus="gameStatus" :CounterChart="gameTiming" :StopPoint="gameStopPoint"
          :AllUserBet="gameAnyBetting" :PoolSize="gamePoolSize" :MaxPool="maxPool" @adminControls="adminControls"
          @depositPool="depositPool" @withdrawPool="withdrawPool" @setMaxPool="setMaxPool"></CrashActions>
      </div>
      <div class="crash-table">
        <div class="crash-table-content">
          <!-- v-if="currentTable" -->
          <BetListedTrendBall :Show="true" :AllUserBet="gameAnyBetting" @togTrend="togTrend"></BetListedTrendBall>
        </div>
      </div>
    </div>
    <div class="sub-content">
      <HistoryCrash v-if="UserInfo?.level === 1" :AllUserOnline="userOnline" />
    </div>
  </div>
</template>

<script>
import { io } from "socket.io-client";

import CrashGame from "../components/crashgame2/CrashGame2.vue";
import BetListedTrendBall from "../components/crashgame2/BetListedTrendBall2.vue";
import CrashActions from "../components/crashgame2/CrashActions2.vue";
import HistoryCrash from "@/components/crashgame2/HistoryCrash2.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CrashPage",
  components: {
    CrashGame,
    BetListedTrendBall,
    CrashActions,
    HistoryCrash,
  },
  data() {
    return {
      currentSub: "crash",
      currentTable: "classic",

      // define socket data
      socket: null,
      socketStatus: null,

      // game information for current user
      gameStatus: "CONNECTING",
      userCurrentBet: null,
      tempBalance: 0,
      gameChartData: {},
      gameHistoryResult: [],
      gameMessageSocket: {},
      gameAnyBetting: {
        bet_classic: [],
        bet_red: [],
        bet_green: [],
        bet_moon: [],
      },
      gameTiming: 0,
      gameCountDown: 0,
      gamePoolSize: 0,
      gameStopPoint: 0,
      maxPool: 0,

      allOnlineUser: [],

      // User bet history divided into 3 arrays
      userBetHistory: undefined,
      userOnline: null,
      showTrendBet: false,
    };
  },
  computed: {
    ...mapGetters({
      TypeBalanceSelected: "socket/TypeBalanceSelected",
      CurrentTab: "socket/CurrentTab",
      UserInfo: "userinfo/UserInfo",
    }),
    UserBetHistory() {
      if (this.userBetHistory) {
        switch (this.CurrentTab) {
          case "userBet":
            return this.userBetHistory.user_history;
          case "userBetMain":
            return this.userBetHistory.user_history_main;
          case "userBetStaking":
            return this.userBetHistory.user_history_staking;
        }
      }
      return {};
    },
  },
  methods: {
    ...mapMutations(["onload", "outload"]),
    async connectWSSToken(tokens) {
      let self = this;
      // VUE_APP_SOCKET_BETA
      // VUE_APP_SOCKET_MAIN
      // eslint-disable-next-line no-undef
      this.socket = await io(process.env.VUE_APP_SOCKET_BETA, {
        reconnectionDelayMax: 10000,
        reconnection: false,
        query: {
          token: tokens,
          lang: this.$i18n.locale,
          chooseGame: 2,
        },
      });

      this.socket.on("private-message", (data) => {
        if (data.action === "SUCCESS") {
          this.$vToastify.success(data.message, "Congratulations !");
        } else {
          this.$vToastify.error("", data.message);
        }
      });

      this.socket.on("GET_BALANCE", (data) => {
        this.tempBalance = data;
        this.$store.commit("socket/SET_BALANCE", data);
      });

      this.socket.on("MESSAGE", (data) => {
        this.messageUser = data;
        if (data.status) {
          if (data.action === "RAW") {
            this.$vToastify.warning(data.message, "Success!");
          } else {
            this.$vToastify.success(data.message, "Success!");
          }
        } else {
          this.$vToastify.error("", data.message);
        }
        if (data.action === 'SUCCESS') {
          const myMessage =
            this.TruncateToDecimals2(data.profit, "", 2) + " " + data.currency;

          this.$store.commit("socket/SET_POP_TOAST", myMessage);
          this.$bvModal.show("notiPop");
          clearTimeout(this.myTimeout);

          this.myTimeout = setTimeout(() => {
            this.$bvModal.hide("notiPop");
          }, 3000);
        }
        // Handle failed bet
        if (!data.status) {
          switch (data.action) {
            case "BET":
              this.$refs.gameAction.isBet = false;
              break;
            case "BET RED":
              this.$refs.gameAction.isBetRed = false;
              break;
            case "BET_GREEN":
              this.$refs.gameAction.isBetGreen = false;
              break;
            case "BET_MOON":
              this.$refs.gameAction.isBetMoon = false;
          }
        }
        console.log(data);
        if (data.status && data.action === "set result Happy 2") {
          this.$refs.gameAction.controlResult = data.result;
        }
      });

      this.socket.on("ADMIN_USER_BET_2", (data) => {
        console.log(data);
        this.gameAnyBetting = data;
      });

      this.socket.on('MAX_POOL_2', (data) => {
        this.maxPool = data;
      })

      // GAME TWO
      this.socket.on("COUNT_DOWN_2", (data) => {
        self.gameCountDown = data;
      });

      this.socket.on("CHANGE_STATUS_2", (data) => {
        this.gameStatus = data;
        if (data === "END") {
          this.UserBet2 = {
            greenBet: 0,
            redBet: 0,
            yellowBet: 0,
            currency: null,
          };
          this.resetGame();
        }
      });

      this.socket.on("GET_RESULT_2", (data) => {
        this.gameTiming = data;
        this.gameStopPoint = data;
      });

      this.socket.on("POOL_SIZE_2", (data) => {
        this.gamePoolSize = data;
      });

      this.socket.on("GET_ALL_USER_BET_2", () => {
        // this.AllUserBet2 = data;
      });
      this.socket.on("GET_BET_RECONNECT_2", (data) => {
        this.UserBet2 = data;
      });
      this.socket.on("HISTORY_USER_2", (data) => {
        this.HistoryBetUser2 = data;
      });
      this.socket.on("HISTORY_2", (data) => {
        this.gameHistory2 = data.data;
      });
      this.socket.on("TOTAL_COUNT_DOWN", (data) => {
        this.totalCountdown = data;
      });
      this.socket.on("GET_TOTAL_USER_ONLINE", (data) => {
        this.userOnline = data;
      });
    },
    async resetGame() {
      const gameAction = await this.$refs.gameAction;
      if (!gameAction) {
        this.resetGame();
      }
      this.$refs.gameAction.controlResult = 0;
      this.gameStopPoint = 0;
    },
    adminControls(input) {
      if (typeof input === "number") {
        this.socket.emit("ADMIN_SET_RESULT_2", { type: 'VALUE', value: input });
      } else {
        this.socket.emit("ADMIN_SET_RESULT_2", { type: input });
      }
    },
    depositPool(input) {
      this.socket.emit("DEPOSIT_POOL_2", input);
    },
    withdrawPool(input) {
      this.socket.emit("WITHDRAW_POOL_2", input);
    },
    togTrend(bool) {
      this.showTrendBet = bool;
      // setTimeout(this.$refs.gameChart.handleResize, 100);
    },
    setMaxPool(input) {
      this.socket.emit('SET_MAX_POOL_2', input);
    }
  },
  async mounted() {
    this.onload();
    const self = this;
    const check = setInterval(async () => {
      const gameChart = await self.$refs.gameChart;
      if (gameChart) {
        clearInterval(check);
        return;
      }
    });

    await new Promise((resolve) => setTimeout(resolve, 500));
    this.outload();
    const tokenSocket = window.$cookies.get("socket_session");
    this.connectWSSToken(tokenSocket.key);
  },
  beforeDestroy() {
    this.socket.disconnect();
    this.$store.commit("socket/SET_BALANCE", {});
  },
};
</script>

<style lang="scss" scoped>
.crash-game-page {
  .crash-game-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0.5rem;

    .crash-game {
      // background-image: url("~@/assets/images/starry.svg"),
      //   linear-gradient(180deg, #081e43 0%, rgba(1, 6, 15, 0.63) 100%);

      // Animation
      // background-size: 200% 200%, 100%;
      // animation: bgPan 10s linear infinite;
      animation-play-state: paused;

      &.go {
        // animation-play-state: running;
      }

      @keyframes bgPan {
        from {
          background-position: 0 0;
        }

        to {
          background-position: 200% -200%;
        }
      }

      ///////////////////////////
    }

    .table-header {
      font-weight: 700;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      padding: 5px 10px;

      .botan {
        opacity: 0.6;

        &.active {
          opacity: 1;
        }
      }

      .privacy-switch {
        height: 25px;
        display: inline-block;

        input[type="checkbox"] {
          height: 0;
          width: 0;
          visibility: hidden;
        }

        label {
          cursor: pointer;
          text-indent: -9999px;
          width: 50px;
          height: 25px;
          background: #1165948a;
          display: block;
          border-radius: 100px;
          position: relative;
          margin-top: -25px;
        }

        label:after {
          content: "";
          position: absolute;
          top: 5px;
          left: 5px;
          width: 15px;
          height: 15px;
          background: #656b7d;
          border-radius: 90px;
          transition: 0.3s;
        }

        input:checked+label {
          background: rgba(255, 203, 18, 0.1);
        }

        input:checked+label:after {
          left: calc(100% - 5px);
          transform: translateX(-100%);
          background: #ffcb12;
        }

        label:active:after {
          width: 50px;
        }
      }
    }

    .crash-table {
      width: 100%;

      .crash-table-content {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;

        @media (min-width: 992px) {}
      }
    }

    @media (min-width: 768px) {
      .table-header {
        padding: 5px 0px;
      }
    }

    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr;

      .crash-game {
        grid-row: 2;
      }

      .crash-table {
        grid-row: 2;

        .crash-table-content {
          grid-template-columns: 1fr auto;
        }
      }

      .table-header {
        display: none;
      }

      &.showTrendBet {
        grid-template-columns: 3.5fr 8.5fr;

        .crash-table {
          .crash-table-content {
            grid-template-columns: 1fr 1fr;
          }
        }
      }
    }
  }

  .tabs-wrapper {
    display: inline-block;
    margin: 30px 10px;

    @media (min-width: 768px) {
      margin: 30px 0px;
    }

    .botan {
      padding: 10px 30px;
    }
  }

  @media (min-width: 768px) {
    padding: 1rem;
  }

  @media (min-width: 1400px) {
    padding: 1rem 2rem;
  }
}
</style>
